import React from 'react';
import Chat from './chat';
import panda from './images/www.bus-artis.fr_.png';


function App() {
  return (
    <div>
      <img
        style={{
          width: '100%'
        }}
        alt="Logo" src={panda} />
      <Chat />
    </div>
  );
}

export default App;